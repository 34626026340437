@import './assets/styles/variables';
@import './assets/styles/theme';
@import './assets/styles/font';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';

@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

// Futura and Proxima Nova
@import url('https://use.typekit.net/nvz3bol.css');

@import './mono-font.scss';

// colors
$white: #fff;
$black: #000;
$grey: #78849e;
$lightGrey: #b4b4c6;
$darkGrey: #78849e;
$purple: #26185c;
$background: #f8f8f8; // BaseGrey
$red: red;

// fonts
$font-family-base: proxima-nova, sans-serif !default;
$header: Futura;
$body: proxima-nova, sans-serif;

:root {
  --font-family: $header;
  --ion-background-color: $background;
  --ion-tab-bar-color-activated: $white;
  --ion-color-success: #1e8f4b;
}

body {
  background-color: $background;
}

body,
html,
p,
div,
.item,
ion-label,
ion-text,
ion-select,
ion-title,
ion-datetime {
  font-family: $body;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-card-title {
  font-family: $header;
}

// Custom colors applied when not using a color theme.
ion-toolbar {
  --background: #ffffff;
  --color: #4f4998;

  //--border-color: #f24aec;
  //--border-width: 4px 0;
  //--border-style: double;
  //
  //--min-height: 80px;
  //--padding-top: 20px;
  //--padding-bottom: 20px;
}

ion-back-button {
  --background: #ffffff;
  --color: #4f4998;
}

video {
  width: 100%;
  height: auto;
  max-height: 100%;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

//div.patient-app-content-header ion-toolbar ion-segment {
//  background-color: #FFFFFF;
//
//  & ion-segment-button {
//    color: greenyellow;
//
//    &:host(.in-toolbar-color:not(.in-segment-color)) .button-native {
//      color: orange;
//    }
//
//    & button {
//      color: #3D3783;
//    }
//  }
//}
//
//:host(.in-toolbar-color:not(.in-segment-color)) .button-native {
//  color: blue;
//}

.li-ionic4-datePicker {
  .modal-wrapper {
    height: 100%;
    max-height: 470px;
    width: 100%;
    max-width: 330px;
    border-radius: 6px;
    background: white;
    --ion-color-primary: $purple;
    --ion-color-primary-tint: $darkGrey;
    --ion-color-primary-contrast: white;
    --ion-color-primary-shade: $darkGrey;

    .dp-selecteddate {
      color: white !important;
      background: $purple !important;
    }

    ion-button {
      color: $purple;
      background: white;
    }

    ion-title {
      color: $purple;
      font-family: $header;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left !important;
      align-items: flex-start !important;
      margin-left: 16px;
      h1 {
        margin: 0 !important;
      }
    }

    ion-toolbar {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.input-error {
  font-size: 12px;
  margin: 5px 0 0 5px;
  color: $red;
}

.picker-wrapper,
.alert-wrapper,
.popover-content {
  background-color: $background !important;
}

ion-fab {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;

  ion-badge {
    margin: 1em;
  }
}

ion-item.menu-item {
  --border-style: none;
}

.patient-item:hover {
  background-color: rgba(var(--ion-color-primary-rgb), 0.5);
}

ion-item.active {
  background-color: var(--ion-color-primary);
  border-radius: 3px;
  font-weight: bold;
  margin: 0 8px;
}

ion-button.card-btn {
  margin-top: 5px;

  ion-icon {
    position: absolute;
    right: 16px; // var(--ion-margin); <- Should set it somehow like this, but couldn't get it working and moving on for now
  }
}

.height-0 {
  height: 0;
  position: relative;
}

.height-100 {
  height: 100%;
  position: relative;
}

.height-point {
  height: 0.5%;
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mono-font {
  font-family: 'Roboto Mono', monospace;
}

.chat-link-email {
  color: white !important;
}

/*
  Apply this class to <ion-select> to have option labels' text wrap.
  (Pass class to component via interfaceOptions property).
  See Appointment Type in appointment-form.component.html for an example.
*/
.alert-select-options-wrap-text {
  .alert-radio-group {
    button {
      height: min-content;
      contain: content;

      .alert-radio-label {
        white-space: pre-line;
      }
    }
  }
}

.wide-select-popover .popover-content {
  width: 90%;
}

.chat-link-url {
  color: white;
  text-decoration: underline;
}

.app-link {
  color: $accent;
  text-decoration: none;
  font-weight: bold;
}

/* * * * * * * * * * * * * * * *
 * Square Payment Form Styles  *
 * * * * * * * * * * * * * * * */

#form-container {
  fieldset {
    border: none;
  }

  .third {
    float: left;
    width: calc((100% - 32px) / 3);
    padding: 0;
    margin: 0 16px 16px 0;
  }

  .third:last-of-type {
    margin-right: 0;
  }

  /* Define how SqPaymentForm iframes should look */
  .sq-input {
    height: 56px;
    box-sizing: border-box;
    border-radius: 6px;
    display: inline-block;
    -webkit-transition: border-color 0.2s ease-in-out;
    -moz-transition: border-color 0.2s ease-in-out;
    -ms-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
    background-color: white;
  }

  /* Define how SqPaymentForm iframes should look when they have focus */
  .sq-input--focus {
    border: 1px solid #6e4eec;
  }

  /* Define how SqPaymentForm iframes should look when they contain invalid values */
  .sq-input--error {
    border: 1px solid #e02f2f;
  }

  #sq-card-number {
    margin-bottom: 16px;
  }

  /* Customize the "Pay with Credit Card" button */
  .button-credit-card {
    border-radius: 50px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.04);
    height: auto;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 16px;
    padding: 16px;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    z-index: 1;
    background: linear-gradient(180deg, #8e72ff -57.14%, #6e4eec 125%);
    border: none;
    color: #fff;
  }

  .button-credit-card:hover {
    background: linear-gradient(180deg, #a791ff -57.14%, #856aef 125%);
    color: #fff;
  }
}

app-book-appointment-dialog {
  .mat-step-label {
    font-size: 0.8em;
    overflow: visible;
  }
}

formly-form {
  h3 {
    white-space: pre-wrap;
  }

  // Selector is for any sub label field this isn't part of a control, just not a free text box and or a select box.
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix:has(formly-field-mat-multicheckbox),
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix:has(formly-field-mat-radio) {
    margin-top: 20px;

    label {
      min-width: 450px;

      mat-label {
        font-size: 20px;
      }
    }
  }

  // Select box selected option text.
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: #000000;
  }

  .mat-mdc-form-field-flex {
    margin-top: 10px;
  }

  .mat-mdc-floating-label {
    left: 0;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-form-field:hover {
    background-color: transparent;

    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  mat-radio-group {
    display: flex;
    flex-direction: column;

    .mdc-radio {
      height: 15px;
      width: 15px;

      .mdc-radio__native-control:enabled:checked + .mdc-radio__background {
        .mdc-radio__outer-circle .mdc-radio__inner-circle {
          border-color: #4f4898;
        }
      }
    }

    .mdc-radio ~ label {
      margin-top: 5px;
    }

    // TODO - The remainder of these styles need to be converted to theme.
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio:not(:disabled):active
      .mdc-radio__native-control:enabled
      + .mdc-radio__background
      .mdc-radio__outer-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio:not(:disabled):active
      .mdc-radio__native-control:enabled
      + .mdc-radio__background
      .mdc-radio__inner-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__outer-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__inner-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio:hover
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__outer-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio:hover
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__inner-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio:not(:disabled):active
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__outer-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button
      .mdc-radio:not(:disabled):active
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__inner-circle {
      border-color: #4f4898;
    }

    .mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
      background-color: #4f4898;
    }

    .mat-mdc-radio-button.mat-accent {
      --mdc-radio-disabled-selected-icon-color: #000;
      --mdc-radio-disabled-unselected-icon-color: #000;
      --mdc-radio-unselected-hover-icon-color: #212121;
      --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-radio-selected-focus-icon-color: #4f4898;
      --mdc-radio-selected-hover-icon-color: #4f4898;
      --mdc-radio-selected-icon-color: #4f4898;
      --mdc-radio-selected-pressed-icon-color: #4f4898;
      --mat-radio-ripple-color: #000;
      --mat-radio-checked-ripple-color: #4f4898;
      --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
    }
  }

  // Opinion questions special treatment as a row of radio buttons.
  formly-opinion-question-wrapper mat-radio-group {
    display: flex;
    flex-direction: row;
    margin-left: -12px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  mat-checkbox {
    .mdc-checkbox:hover .mdc-checkbox__native-control:checked ~ .mdc-checkbox__ripple {
      background-color: #cac8e0;
    }

    .mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
      background-color: #cac8e0;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
      border-color: #4f4898;
      background-color: #cac8e0;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
      border-color: #4f4898;
      background-color: #cac8e0;
    }

    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
      border-color: #4f4898;
      background-color: #cac8e0;
    }

    .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox:not(:disabled):active
      .mdc-checkbox__native-control[data-indeterminate='true']:enabled
      ~ .mdc-checkbox__background {
      border-color: #4f4898;
      background-color: #cac8e0;
    }
  }

  ion-item {
    margin-bottom: 16px;
  }

  .mat-mdc-checkbox {
    display: block;
    margin: 0.5em;
  }

  .mat-mdc-radio-button {
    display: block;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label {
    white-space: normal !important;
  }

  .mat-mdc-floating-label {
    white-space: inherit !important;
    text-overflow: inherit !important;
    overflow: visible !important;
    text-align: center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-label {
    white-space: inherit !important;
    text-overflow: inherit !important;
    overflow: visible !important;
    text-align: center;
  }

  .mat-mdc-form-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline {
        position: initial !important;
        display: block;
        margin-top: -1px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-subscript-wrapper,
      .mat-form-field-ripple {
        position: initial !important;
        display: table;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-subscript-wrapper {
        min-height: calc(1em + 1px);
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-label-wrapper {
    overflow: visible !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    display: flex;
    flex-direction: column;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label-wrapper {
      position: static !important;
      width: 100% !important;
      order: -1;
      margin-bottom: 1em;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-label {
        -webkit-transform: none !important;
        transform: none !important;
        width: 100% !important;
        overflow: visible !important;
        position: static !important;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    white-space: normal !important;
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
      margin: 3px 8px 0 0 !important;
    }
  }
}

// Used in display component, needs to be here to overwrite ionic library styling
.document-image {
  height: 100%;
  overflow-x: scroll;
  img {
    transform-origin: top left;
  }
}

.document-container {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
}

// Used in appointment-card component, needs to be in global styles to function
.reschedule-modal .modal-wrapper {
  max-width: 530px !important;
}

div.patient-app-sidebar {
  width: 220px;
}

div.patient-app-body {
  background: #f8f8f8;
}

div.patient-app-content-header,
div.patient-app-content-footer {
  margin-left: 220px;
  background: #f8f8f8;
}

div.patient-app-content {
  margin-left: 220px;
  height: 70vh;
  overflow: auto;
}

div.patient-app-content-no-header-or-footer {
  margin-left: 220px;
  height: 80vh;
  overflow: auto;
}

div.patient-app-content-ion-row-wrapper {
  margin-left: 220px;
}

div.patient-app-content-ion-row {
  height: 70vh;
  overflow: auto;
}
