// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// colors
$white: #fff;
$black: #000;
$grey: #78849e;
$lightGrey: #b4b4c6;
$darkGrey: #78849e;
$purple: #26185c;

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #26185c;
  --ion-color-primary-rgb: 38, 24, 92;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4f4898;

  /** secondary **/
  --ion-color-secondary: #4f4898;
  --ion-color-secondary-rgb: 79, 72, 152;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1c2069;
  --ion-color-secondary-tint: #7f74c9;

  /** tertiary **/
  --ion-color-tertiary: #78849e;
  --ion-color-tertiary-rgb: 120, 132, 158;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4b5770;
  --ion-color-tertiary-tint: #a7b4cf;

  /** accent **/
  --ion-color-accent: #127e97;
  --ion-color-accent-rgb: 18, 126, 151;
  --ion-color-accent-contrast: #ffffff;
  --ion-color-accent-contrast-rgb: 255, 255, 255;
  --ion-color-accent-shade: #106f85;
  --ion-color-accent-tint: #2a8ba1;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ff6959;
  --ion-color-warning-rgb: 255, 105, 89;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #c6362f;
  --ion-color-warning-tint: #ff9b86;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 240, 65, 65;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #fff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.ion-color-accent {
  --ion-color-base: var(--ion-color-accent);
  --ion-color-base-rgb: var(--ion-color-accent-rgb);
  --ion-color-contrast: var(--ion-color-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent-shade);
  --ion-color-tint: var(--ion-color-accent-tint);
}
