@font-face {
  font-family: 'MessinaSans';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/MessinaSans/MessinaSans-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'MessinaSans';
  font-weight: 200;
  font-style: italic;
  src: url('../fonts/MessinaSans/MessinaSans-ThinItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'MessinaSans';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/MessinaSans/MessinaSans-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'MessinaSans';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/MessinaSans/MessinaSans-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'MessinaSans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/MessinaSans/MessinaSans-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'MessinaSans';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/MessinaSans/MessinaSans-SemiBoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'MessinaSans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/MessinaSans/MessinaSans-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'MessinaSans';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/MessinaSans/MessinaSans-BoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Steradian';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Steradian/Steradian-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Steradian';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Steradian/Steradian-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Steradian';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Steradian/Steradian-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Steradian';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/Steradian/Steradian-SemiBoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Steradian';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Steradian/Steradian-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Steradian';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Steradian/Steradian-BoldItalic.woff2') format('woff2');
}
