@use '@angular/material' as mat;

$mat-nicepurple: (
  50: #e5e3eb,
  100: #bebace,
  200: #938cae,
  300: #675d8d,
  400: #473b74,
  500: #26185c,
  600: #221554,
  700: #1c114a,
  800: #170e41,
  900: #0d0830,
  A100: #796aff,
  A200: #4b37ff,
  A400: #1d04ff,
  A700: #1700ea,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-nicepurplelite: (
  50: #eae9f3,
  100: #cac8e0,
  200: #a7a4cc,
  300: #847fb7,
  400: #6963a7,
  500: #4f4898,
  600: #484190,
  700: #3f3885,
  800: #36307b,
  900: #26216a,
  A100: #afaaff,
  A200: #7f77ff,
  A400: #4f44ff,
  A700: #372aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-nicewarn: (
  50: #ffedeb,
  100: #ffd2cd,
  200: #ffb4ac,
  300: #ff968b,
  400: #ff8072,
  500: #ff6959,
  600: #ff6151,
  700: #ff5648,
  800: #ff4c3e,
  900: #ff3b2e,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffdcda,
  A700: #ffc3c0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-primary: mat.define-palette($mat-nicepurple, 500, 300, 700);
$app-accent: mat.define-palette($mat-nicepurplelite, 500, 300, 700);
$app-warn: mat.define-palette($mat-nicewarn, 500);

$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);

$theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);
$background: #ffffff;
$background-dark: #fafafa;
// $background: map-get($theme, background);
$foreground: map-get($theme, foreground);

$link-color: $primary;
$link-hover-color: currentColor;
$link-hover-decoration: none;
